import { images } from '../constants';

const home = [
    {
        id: 1,
        image: images.home1,
        title: 'يَا أَيُّهَا النَّاسُ إِنَّا خَلَقْنَاكُم مِّن ذَكَرٍ وَأُنثَىٰ وَجَعَلْنَاكُمْ شُعُوبًا وَقَبَائِلَ لِتَعَارَفُوا ۚ إِنَّ أَكْرَمَكُم',
        titlefr: 'Ô hommes ! Nous vous avons créés d\'un mâle et d\'une femelle, et Nous avons fait de vous des nations et des tribus, pour que vous vous entre-connaissiez. Le plus noble d\'entre vous, auprès d\'Allah, est le plus pieux. Allah est certes Omniscient et Grand-Connaisseur.',
        content: '(SURAH AL-HuJuRAT VERSE 13)',
    },
    {
        id: 2,
        image: images.home2,
        title: "قال النبي (ﷺ) : بني الإسلام على خمس شهادة أن لا إله إلا الله وأن محمدا رسول الله، وإقام الصلاة، وإيتاء الزكاة، والحج، وصوم رمضان\n",
        titlefr: "le Prophète (ﷺ) a dit: « L’Islam est bâtie sur cinq choses: l’attestation qu’il n’y a pas d’autre divinité qui mérite d’être adorée si ce n’est Allah et que Muhammad est le Messager d’Allah (*), l’accomplissement de la prière, le fait de s’acquitter de la zakat, le hajj et le fait de jeûner le Ramadan ».",
        content: 'Sahih Bukhari 8',
    },
    {
        id: 2,
        image: images.home2,
        title: 'فَقُلْتُ اسْتَغْفِرُوا رَبَّكُمْ إِنَّهُ كَانَ غَفَّارًا (10) يُرْسِلِ السَّمَاءَ عَلَيْكُمْ مِدْرَارًا (11) وَيُمْدِدْكُمْ بِأَمْوَالٍ وَبَنِينَ وَيَجْعَلْ لَكُمْ جَنَّاتٍ وَيَجْعَلْ لَكُمْ أَنْهَارًا(12)',
        titlefr: 'J\'ai donc dit: « Implorez le pardon de votre Seigneur, car Il est grand Pardonneur (10), pour qu\'Il vous envoie du ciel, des pluies abondantes (11),et qu\'Il vous accorde beaucoup de biens et d\'enfants, et vous donne des jardins et vous donne des rivières. (12)',
        content: '(SURAH NUH VERSE 10-12)',
    },
    {
        id: 2,
        image: images.home2,
        title:  " قال رسول الله (ﷺ) : كلمتان خفيفتان على اللسان ، ثقيلتان في الميزان ، حبيبتان إلى الرحمن : سبحان الله وبحمده ، سبحان الله العظيم\n",
        titlefr:  "le Prophète (ﷺ) a dit: « Deux paroles sont légères sur la langue, lourdes dans la balance et aimées par le Miséricordieux: Gloire et louange à Allah (sobhanalah wa bihamdih), Gloire à Allah l’Immense (sobhanalah al ‘adhim)",
        content: 'Sahih Bukhari',
    },

];

export default home;
