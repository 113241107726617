import React from 'react';
import {
    Home,
    About,
    Counter,
    Services,
    Team,
    Testimonials,
    Blogs,
    Pillars,
    Prayers,
    Events,
    Causes,
    GallerySection
} from '../../sections';


const HomePage = () => {
    return (
        <div className='homepage'>
            <Events/>

            <Home/>
            <About/>
            <Pillars/>

            <Blogs />
            <Services/>

            {
                //<GallerySection/>
                //<Team/>
            }
            <Prayers/>

            <Causes/>

            {false && <Testimonials/>}
            {false && <Counter/>}
            {
                //<Blogs/>
            }
        </div>
    )
}

export default HomePage;
